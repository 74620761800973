import React, { useState, useEffect } from "react";
import {
  collection,
  setDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import "./Dashboard.css"; // インポート

const Dashboard = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [workType, setWorkType] = useState("現場");
  const [totalHours, setTotalHours] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [settings, setSettings] = useState({
    employeeId: "",
    defaultClockIn: "09:00",
    defaultClockOut: "18:00",
    breakStartTime: "12:00",
    breakDuration: "01:00",
    workTimeUnit: "1分",
    lastWorkType: "現場",
    treatEarlyClockInAsDefault: true, // デフォルト設定をONに
  });
  const [showSettings, setShowSettings] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [loading, setLoading] = useState(false);
  //   const [day, setDay] = useState(
  //     new Date().getDate().toString().padStart(2, "0")
  //   );

  useEffect(() => {
    fetchHolidays(year);
  }, [year]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [user, year, month, holidays]);

  useEffect(() => {
    if (user && records.length > 0) {
      calculateTotalHours(records);
    }
  }, [records]);

  useEffect(() => {
    if (settings.lastWorkType) {
      setWorkType(settings.lastWorkType);
    }
  }, [settings.lastWorkType]);

  const fetchHolidays = async (year) => {
    try {
      const response = await fetch(
        `https://date.nager.at/api/v3/publicholidays/${year}/JP`
      );
      const data = await response.json();
      const holidayDates = data.map((holiday) => holiday.date);
      setHolidays(holidayDates);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const fetchRecords = async () => {
    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    const recordsRef = collection(db, "time_cards", user.uid, "records");
    const q = query(
      recordsRef,
      where("date", ">=", startDate),
      where("date", "<=", endDate)
    );
    const snapshot = await getDocs(q);
    const recordsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const days = updateDays(year, month);
    const allRecords = days.map((day) => {
      const date = `${year}-${month}-${day}`;
      const record = recordsData.find((record) => record.date === date);
      const dayOfWeek = new Date(date).getDay();
      const isHoliday = holidays.includes(date);
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      return (
        record || {
          date,
          workType: isWeekend || isHoliday ? "" : null,
          clockIn: "",
          clockOut: "",
        }
      );
    });

    setRecords(allRecords);
    calculateTotalHours(recordsData);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsRef = doc(db, "settings", user.uid);
      const settingsDoc = await getDoc(settingsRef);
      if (settingsDoc.exists()) {
        setSettings(settingsDoc.data());
      } else {
        // 設定が取得できない場合のデフォルト値
        setSettings({
          employeeId: "",
          defaultClockIn: "09:00",
          defaultClockOut: "18:00",
          breakStartTime: "12:00",
          breakDuration: "01:00",
          workTimeUnit: "1分",
          lastWorkType: "現場",
        });
      }
    };

    if (user) {
      fetchSettings().then(() => {
        if (records.length > 0) {
          calculateTotalHours(records); // 設定をロードした後に合計時間を再計算
        }
      });
    }
  }, [user]);

  const handleClockIn = async () => {
    setLoading(true);
    try {
      const now = new Date();
      const date = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
      const time = now.toTimeString().substr(0, 5);

      const docRef = doc(db, "time_cards", user.uid, "records", date);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        if (
          !window.confirm(
            "既に本日の勤怠記録が存在しますが上書きしてよろしいですか？"
          )
        ) {
          setLoading(false);
          return;
        }
      }

      await setDoc(
        doc(db, "time_cards", user.uid, "records", date),
        {
          date,
          workType,
          clockIn: time,
          clockOut: null,
          breakDur: null,
        },
        { merge: true }
      );

      await saveLastWorkType(workType);
      fetchRecords();
    } finally {
      setLoading(false);
    }
  };

  const handleClockOut = async () => {
    setLoading(true);
    try {
      const now = new Date();
      const date = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
      const time = now.toTimeString().substr(0, 5);

      const docRef = doc(db, "time_cards", user.uid, "records", date);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        alert("先に出勤の記録をしてください");
        setLoading(false);
        return;
      }

      let breakDuration = settings.breakDuration;

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.clockOut) {
          if (
            !window.confirm(
              "既に本日の退勤記録が存在しますが上書きしてよろしいですか？"
            )
          ) {
            setLoading(false);
            return;
          }
        }
        breakDuration = data.breakDur || settings.breakDuration;
      }

      await updateDoc(doc(db, "time_cards", user.uid, "records", date), {
        workType: workType,
        clockOut: time,
        breakDur: breakDuration,
      });

      await saveLastWorkType(workType);
      fetchRecords();
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  const handleDeleteRecord = async () => {
    await deleteDoc(
      doc(db, "time_cards", user.uid, "records", editRecord.date)
    );
    setEditRecord(null);
    fetchRecords();
  };

  const handleCsvExport = () => {
    const validRecords = records.filter((record) => record.clockIn); // 出勤時間が記録されているレコードのみをフィルタリング
    const csvContent = validRecords
      .map((record) => {
        const date = new Date(record.date);
        const day = date.getDate().toString().padStart(2, "0");
        return `${day},${record.workType},${record.clockIn},${
          record.clockOut
        },${record.breakDur || "01:00"}`;
      })
      .join("\n");

    const csvHeader = "日,出社区分,出勤時間,退勤時間,休憩時間\n";
    const csvData = csvHeader + csvContent;

    // UTF-8 BOMを追加して文字化けを防ぐ
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${String(year).slice(2)}${month}.csv`;
    link.click();
  };

  const saveLastWorkType = async (workType) => {
    await updateDoc(doc(db, "settings", user.uid), {
      lastWorkType: workType,
    });

    // settingsの値を更新
    setSettings((prevSettings) => ({
      ...prevSettings,
      lastWorkType: workType,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", weekday: "short" };
    return date
      .toLocaleDateString("ja-JP", options)
      .replace("/", "日(")
      .replace(" ", ")");
  };

  const calculateTotalHours = (recordsData) => {
    let totalMinutes = 0;
    const interval = getIntervalInMinutes(settings.workTimeUnit);

    recordsData.forEach((record) => {
      if (record.clockIn && record.clockOut) {
        let clockInTime = new Date(`1970-01-01T${record.clockIn}:00`);
        let clockOutTime = new Date(`1970-01-01T${record.clockOut}:00`);

        if (
          settings.treatEarlyClockInAsDefault &&
          clockInTime < new Date(`1970-01-01T${settings.defaultClockIn}:00`)
        ) {
          clockInTime = new Date(`1970-01-01T${settings.defaultClockIn}:00`);
        }

        if (settings.workTimeUnit !== "1分") {
          const roundedClockIn = roundTime(
            clockInTime.toTimeString().substr(0, 5),
            interval,
            "start"
          );
          const roundedClockOut = roundTime(
            clockOutTime.toTimeString().substr(0, 5),
            interval,
            "end"
          );
          clockInTime = new Date(`1970-01-01T${roundedClockIn}:00`);
          clockOutTime = new Date(`1970-01-01T${roundedClockOut}:00`);
        }

        const breakTime = record.breakDur
          ? new Date(`1970-01-01T${record.breakDur}:00`)
          : new Date(`1970-01-01T01:00:00`);
        const breakMinutes = breakTime.getHours() * 60 + breakTime.getMinutes();
        const diff = (clockOutTime - clockInTime) / 1000 / 60 - breakMinutes; // 各日の休憩時間をマイナス
        totalMinutes += diff;
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    setTotalHours(`${hours}時間${minutes}分`);
  };

  const days = updateDays(year, month);

  const handleEditRecord = async (record) => {
    console.log(settings);

    let newRecord = { ...record };

    // 足りないデータを設定DBのデータから取得して埋める
    if (!record.clockIn || record.clockIn === "" || record.clockIn === null) {
      newRecord.clockIn = settings.defaultClockIn;
    }
    if (
      !record.clockOut ||
      record.clockOut === "" ||
      record.clockOut === null
    ) {
      newRecord.clockOut = settings.defaultClockOut;
    }
    if (
      !record.breakDur ||
      record.breakDur === "" ||
      record.breakDur === null
    ) {
      newRecord.breakDur = settings.breakDuration;
    }
    if (
      !record.workType ||
      record.workType === "" ||
      record.workType === null
    ) {
      newRecord.workType = settings.lastWorkType || "現場"; // 最後の出社区分をデフォルトで設定
    }

    setEditRecord(newRecord);
    setIsNewRecord(!record.clockIn && !record.clockOut);
  };

  const handleSettingChange = (key, value) => {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
  };

  const handleSaveRecord = async () => {
    setLoading(true);
    try {
      if (isNewRecord) {
        await setDoc(
          doc(db, "time_cards", user.uid, "records", editRecord.date),
          {
            date: editRecord.date,
            workType: editRecord.workType,
            clockIn: editRecord.clockIn,
            clockOut: editRecord.clockOut,
            breakDur: editRecord.breakDur,
          }
        );
      } else {
        await updateDoc(
          doc(db, "time_cards", user.uid, "records", editRecord.date),
          {
            workType: editRecord.workType,
            clockIn: editRecord.clockIn,
            clockOut: editRecord.clockOut,
            breakDur: editRecord.breakDur,
          }
        );
      }
      await saveLastWorkType(editRecord.workType);
      setEditRecord(null);
      setIsNewRecord(false);
      fetchRecords();
    } finally {
      setLoading(false);
    }
  };

  const handleApply = async () => {
    const now = new Date();
    const date = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

    const docRef = doc(db, "time_cards", user.uid, "records", date);
    const docSnap = await getDoc(docRef);

    let newRecord;
    if (docSnap.exists()) {
      newRecord = docSnap.data();
    } else {
      newRecord = {
        date,
      };
    }

    handleEditRecord(newRecord);
  };

  const handleSaveSettings = async () => {
    setLoading(true);
    try {
      await setDoc(doc(db, "settings", user.uid), settings);
      setShowSettings(false);
      calculateTotalHours(records);
    } finally {
      setLoading(false);
    }
  };

  const roundTime = (time, interval, type) => {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;

    let roundedMinutes;
    if (type === "start") {
      roundedMinutes = Math.ceil(totalMinutes / interval) * interval;
    } else {
      roundedMinutes = Math.floor(totalMinutes / interval) * interval;
    }

    const roundedHours = Math.floor(roundedMinutes / 60);
    const roundedRestMinutes = roundedMinutes % 60;
    return `${roundedHours.toString().padStart(2, "0")}:${roundedRestMinutes
      .toString()
      .padStart(2, "0")}`;
  };

  const getIntervalInMinutes = (unit) => {
    switch (unit) {
      case "15分":
        return 15;
      case "30分":
        return 30;
      case "1時間":
        return 60;
      default:
        return 1; // 1分
    }
  };

  const handleOpenSettings = async () => {
    const settingsRef = doc(db, "settings", user.uid);
    const settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) {
      setSettings(settingsDoc.data());
    } else {
      // 設定が取得できない場合のデフォルト値
      setSettings({
        employeeId: "",
        defaultClockIn: "09:00",
        defaultClockOut: "18:00",
        breakStartTime: "12:00",
        breakDuration: "01:00",
        workTimeUnit: "1分",
      });
    }
    setShowSettings(true);
  };

  return (
    <div className="dashboard">
      <h3>ようこそ, {user.email}さん</h3>
      <span className="logout-link" onClick={handleLogout}>
        ログアウト
      </span>
      <div className="csv-link" onClick={handleCsvExport}>
        CSV形式で出力
      </div>
      <span className="settings-link" onClick={handleOpenSettings}>
        設定
      </span>

      <div className="current-date">
        <span>
          {currentTime.toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </div>

      <div className="current-time">
        <span>
          {currentTime.toLocaleTimeString("ja-JP", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </span>
      </div>

      <div className="button-row">
        <select value={workType} onChange={(e) => setWorkType(e.target.value)}>
          <option value="現場">現場</option>
          <option value="自社">自社</option>
          <option value="ﾃﾚﾜｰｸ">ﾃﾚﾜｰｸ</option>
        </select>
        <button className="clock-in-button" onClick={handleClockIn}>
          出勤
        </button>
        <button className="clock-out-button" onClick={handleClockOut}>
          退勤
        </button>
        <button className="apply-button" onClick={handleApply}>
          申請
        </button>
      </div>

      <h2>勤怠記録（合計: {totalHours}）</h2>

      <div className="navigation">
        <button
          className="prev-button"
          onClick={() => {
            setMonth((prevMonth) => {
              const newMonth = parseInt(prevMonth) - 1;
              if (newMonth < 1) {
                setYear(year - 1);
                return "12";
              }
              return newMonth.toString().padStart(2, "0");
            });
          }}
        >
          ◀
        </button>
        <span>{`${year}年${month}月`}</span>
        <button
          className="next-button"
          onClick={() => {
            setMonth((prevMonth) => {
              const newMonth = parseInt(prevMonth) + 1;
              if (newMonth > 12) {
                setYear(year + 1);
                return "01";
              }
              return newMonth.toString().padStart(2, "0");
            });
          }}
        >
          ▶
        </button>
      </div>

      <ul>
        {records.map((record, index) => (
          <li
            key={index}
            className={getDayClass(record.date, record.workType, holidays)}
            onClick={() => handleEditRecord(record)}
          >
            <div className="record-details">
              <div>{formatDate(record.date)}</div>
              <div>{record.workType}</div>
              <div>{record.clockIn}</div>
              <div>{record.clockOut}</div>
              {record.clockIn ? <div>{record.breakDur}</div> : <div></div>}{" "}
              {/* レコードが存在する場合のみ休憩時間を表示 */}
            </div>
          </li>
        ))}
      </ul>

      {editRecord && (
        <div className="edit-dialog">
          <div className="edit-dialog-header">
            <h3>
              {isNewRecord ? "申請" : "編集"} -{" "}
              {new Date(editRecord.date).toLocaleDateString("ja-JP", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                weekday: "short",
              })}
            </h3>
            <span className="close-button" onClick={() => setEditRecord(null)}>
              ❌
            </span>
          </div>
          <div className="form-group">
            <label>出社区分:</label>
            <select
              value={editRecord.workType}
              onChange={(e) =>
                setEditRecord({ ...editRecord, workType: e.target.value })
              }
            >
              <option value="現場">現場</option>
              <option value="自社">自社</option>
              <option value="ﾃﾚﾜｰｸ">ﾃﾚﾜｰｸ</option>
            </select>
          </div>
          <div className="form-group">
            <label>出勤時間:</label>
            <input
              type="time"
              value={editRecord.clockIn}
              onChange={(e) =>
                setEditRecord({ ...editRecord, clockIn: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>退勤時間:</label>
            <input
              type="time"
              value={editRecord.clockOut}
              onChange={(e) =>
                setEditRecord({ ...editRecord, clockOut: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>休憩時間:</label>
            <input
              type="time"
              value={editRecord.breakDur}
              onChange={(e) =>
                setEditRecord({ ...editRecord, breakDur: e.target.value })
              }
            />{" "}
            {/* 休憩時間の編集 */}
          </div>
          <div className="button-row">
            <button onClick={handleSaveRecord}>
              {isNewRecord ? "申請" : "保存"}
            </button>
            {!isNewRecord && (
              <button onClick={handleDeleteRecord} className="delete-button">
                削除
              </button>
            )}
          </div>
        </div>
      )}
      {showSettings && (
        <div className="settings-dialog">
          <div className="settings-dialog-header">
            <h3>設定</h3>
            <span
              className="close-button"
              onClick={() => setShowSettings(false)}
            >
              ❌
            </span>
          </div>
          <div className="form-group">
            <label>社員ID</label>
            <input
              type="text"
              value={settings.employeeId}
              onChange={(e) =>
                handleSettingChange("employeeId", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>出勤定時</label>
            <input
              type="time"
              value={settings.defaultClockIn}
              onChange={(e) =>
                handleSettingChange("defaultClockIn", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>退勤定時</label>
            <input
              type="time"
              value={settings.defaultClockOut}
              onChange={(e) =>
                handleSettingChange("defaultClockOut", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>休憩開始時刻</label>
            <input
              type="time"
              value={settings.breakStartTime}
              onChange={(e) =>
                handleSettingChange("breakStartTime", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>休憩時間</label>
            <input
              type="time"
              value={settings.breakDuration}
              onChange={(e) =>
                handleSettingChange("breakDuration", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>勤務時間単位</label>
            <select
              value={settings.workTimeUnit}
              onChange={(e) =>
                handleSettingChange("workTimeUnit", e.target.value)
              }
            >
              <option value="1分">1分</option>
              <option value="15分">15分</option>
              <option value="30分">30分</option>
              <option value="1時間">1時間</option>
            </select>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={settings.treatEarlyClockInAsDefault}
                onChange={(e) =>
                  handleSettingChange(
                    "treatEarlyClockInAsDefault",
                    e.target.checked
                  )
                }
              />
              出勤定時前の打刻は定時扱いにする
            </label>
          </div>
          <div className="button-row">
            <button onClick={handleSaveSettings}>保存</button>
          </div>
        </div>
      )}
      {loading && <div className="loading-spinner">Loading...</div>}
    </div>
  );
};

const getDayClass = (dateString, workType, holidays) => {
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  const isHoliday = holidays.includes(dateString);
  if (isHoliday) {
    return "holiday";
  }
  if (dayOfWeek === 0) {
    return "sunday";
  }
  if (dayOfWeek === 6) {
    return "saturday";
  }
  if (workType === null) {
    return "weekday-no-record";
  }
  return "";
};

const updateDays = (year, month) => {
  const lastDay = new Date(year, month, 0).getDate();
  const days = Array.from({ length: lastDay }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  return days;
};

export default Dashboard;
